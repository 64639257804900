<template>
  <div class="jumpContain">
    <h2>上传发票</h2>
    <p class="f16">上传发票后，您可在记账系统中自动生成财务凭证，也可前往票管家系统查询自动归档的发票数据。</p>
    <p class="f16 ">支持上传的发票类型：纸质普票（不含卷票）、纸质专票、电子普票、电子专票、全电普票、全电专票</p>
        <file-upload class="upLoad" accept=".pdf,.ofd,.jpg,.jpeg,.png,.bmp,.gif" :multiple="true" :drop="true" :drop-directory="true" v-model="fileList" @input="updatetValue" @input-filter="inputFilter" ref="upload">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text f20">将文件、文件夹拖到此处，或<em>点击上传</em> <p>支持扩展名：pdf、ofd、图片</p> </div>
      </file-upload>
      <div class="listWrap">
        <ul v-if="fileList.length" class="showList">
          <li v-for="( file, index) in fileList" :key="file.id">
            <span>{{file.name}}</span>
            <div class="remove" @click="removeHandle(index)"><i class="el-icon-close"></i></div>
          </li>
        </ul>
      </div>
      <div class="tipsWrap">
        <p class="tips" :class="{show: message != '' }">{{message}}</p>
      </div>
      <div class="btnWrap">
        <el-button :loading="confirmLoading" type="primary" class="btn2" @click="importConfirm" :disabled="verifyStatus">确认上传</el-button>
        <el-button :loading="cancelLading" type="primary" class="btn2" @click="importCancel" :disabled="verifyStatus">取消上传</el-button>
      </div>
  </div>
</template>

<script>
  import FileUpload from 'vue-upload-component'
  import axios from "axios";
  import XLSX from "xlsx";
import log from '../../store/modules/recycleBin';
  export default {
    components: {
      FileUpload
    },
    data() {
      return {
        fileList: [],
        fileData: [],
        isdeling: false,
        tableData: [],
        status: false,
        message: '',
        verifyStatus:false,
        accToken:this.$route.query.accToken,
        invoiceType:this.$route.query.invoiceType,
        accUserId:this.$route.query.accUserId,
        requestFrom:this.$route.query.requestFrom,//判断代理记账还是票管家发sing的请求
        isOpenEinv:this.$route.query.isOpenEinv,//是否开通票关键
        phone:this.$route.query.phone,
        asId:this.$route.query.asId,
        confirmLoading:false,
        cancelLading:false,
      }
    },
    methods: {
      updatetValue(value) {  //文件上传状态更改
      console.log(value,'上传缓存在本地的文件');
        this.fileList = value;
        let  fileNameSet =new Set();
        for (let i = 0; i < this.fileList.length; i++) {
          fileNameSet.add(this.fileList[i].file.name);
        }
        if(fileNameSet.size!=this.fileList.length){
          einvAlert.error("提示","该文件已添加！！")
          this.removeHandle(this.fileList.length-1);
          return false;
        }
        console.log(value);
      },
      inputFilter(newFile, oldFile, prevent) {
        if (newFile && !oldFile) {
          // const isLt1M = newFile.size / 1024 / 1024 < 1;
          const isLt10M = newFile.size / 1024 / 1024 < 10;
        // 添加文件
        // 过滤非图片文件
        // 不会添加到 files 去
          if (!/\.(pdf|ofd|jpg|jpeg|png|bmp|gif)$/i.test(newFile.name)) {  //过滤文件
            this.message = '不支持"pdf、ofd、图片"以外的格式！'
            // einvAlert.error("提示",this.message);
            return prevent()
          }
          if (!isLt10M) {  //过滤文件
            this.message = '上传文件大小不能超过 10MB!'
            // einvAlert.error("提示",this.message);
            return prevent()
          }
        }

      if (newFile && oldFile) {
        // 更新文件
        // 增加版本号
        if (!newFile.version) {
          newFile.version = 0
        }
        newFile.version++
      }

      if (!newFile && oldFile) {
        // 移除文件
        // 拒绝删除文件
        return prevent()
      }
    },
    removeHandle(index){
      this.fileList.splice(index,1)

    },
      importCancel() {
        this.fileList = [];
        this.fileData = '';
        this.status = false;
        this.message = '';
        sessionStorage.clear();
        localStorage.clear();
        this.$router.back(-1);


      },
      importConfirm() {
        //如果有刷新次数缓存，先去掉
        var userId  = this.getToken("userId");
        var refreshTime = this.getToken("refreshTime"+userId);
        if(refreshTime){
          this.removeItemToken("refreshTime"+userId);
        }
        this.confirmLoading =true
        this.isdeling = true
        let formData = new FormData(); // 批量上传
        //遍历数据，手动注入formData
        for (let i = 0; i < this.fileList.length; i++) {
            formData.append("files", this.fileList[i].file);
        }
        formData.append("companyId", this.companyId);
        formData.append("requestFrom", this.requestFrom);
        formData.append("isOpenEinv", this.isOpenEinv);
        formData.append("type", this.invoiceType);
        formData.append("userId", this.accUserId);
        formData.append("asId", this.asId);

        if (this.fileList && this.fileList.length > 0) {
          /* 路由传值 */
          this.$store.dispatch("PostPreUpload",formData).then(res => { // 以文件传输格式 file: (binary) 进行上传
            this.setToken('preUploadClientId',this.requestFrom+'_'+this.companyId+'_'+this.invoiceType+'_'+this.accUserId)
            this.setToken('uploadType',1);  //定义代理记账 上传类型 --> 纸票确定 iframe通讯
            if(res.success && res.data.needCheck){
              setTimeout(()=>{
                this.$router.push({
                  path:'/invoiceInfo',
                  query:{
                    /* 业务类型  1-进项，2-销项 */
                    invoiceType:this.invoiceType,
                    requestFrom:this.requestFrom
                    // visible:true
                  }
                })
              },0)
              this.$router.push({
                path:'/invoiceInfo',
                query:{
                  /* 业务类型  1-进项，2-销项 */
                  invoiceType:this.invoiceType,
                  requestFrom:this.requestFrom
                  // visible:true
                }
              })
              // this.confirmLoading = false
              this.setToken('uploadListShow',true)
            }else if(res.success &&!res.data.needCheck){
              this.$store.dispatch('PostUpload',{
                clientId:this.getToken('preUploadClientId')
              }).then(res =>{
                this.fileList = []
                // 以文件传输格式 file: (binary) 进行上传
                // employImport(formData).then(res => { // 以文件传输格式 file: (binary) 进行上传
                let  _this=this;
                if (res.success && res.data.isSuccess) { //导入成功
                  einvAlert.success("导入成功","导入成功"+res.data.successCount+"张");
                  setTimeout(function (){
                      //定义代理记账 上传类型 --> 纸票确定 iframe通讯
                      window.parent.postMessage("导入成功", '*');
                    _this.confirmLoading = false
                  },500);
                }else if(res.success){
                  einvAlert.confirm(function (){         //导入失败
                        _this.uploadError(res.data.logId)
                      },"<span>导入成功"+res.data.successCount+"张</span>,"+"<span style='color: red'>导入失败"+res.data.failCount+"张</span>",
                      '下载错误记录','取消');
                  _this.confirmLoading = false
                }else{
                  einvAlert.error("提示",res.msg||res.message)
                  _this.confirmLoading = false
                }

              }).catch(err => {
                this.confirmLoading = false
                err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
              })
            }else if(!res.success){
              einvAlert.error("提示",res.msg)
              this.confirmLoading = false
            }
            this.$parent.importVis = false
            this.fileList = []
            // this.$parent.getList()
            }).catch(err => {
              this.isdeling = false
              this.confirmLoading = false
          })
        }else{
          this.message = '请选择上传文件';
          this.isdeling = false
          this.confirmLoading = false
        }
      },

      json2Excel(dataSource) {
        var wopts = {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        };
        var workBook = {
          SheetNames: ['Sheet1'],
          Sheets: {},
          Props: {}
        };
        //1、XLSX.utils.json_to_sheet(data) 接收一个对象数组并返回一个基于对象关键字自动生成的“标题”的工作表，默认的列顺序由使用Object.keys的字段的第一次出现确定
        //2、将数据放入对象workBook的Sheets中等待输出
        workBook.Sheets['Sheet1'] = XLSX.utils.json_to_sheet(dataSource)
        //3、XLSX.write() 开始编写Excel表格
        //4、changeData() 将数据处理成需要输出的格式
        this.saveAs(new Blob([this.changeData(XLSX.write(workBook, wopts))], { type: 'application/octet-stream' }), "错误信息")
      },
      changeData(s) {
        //如果存在ArrayBuffer对象(es6) 最好采用该对象
        if (typeof ArrayBuffer !== 'undefined') {
          //1、创建一个字节长度为s.length的内存区域
          var buf = new ArrayBuffer(s.length);
          //2、创建一个指向buf的Unit8视图，开始于字节0，直到缓冲区的末尾
          var view = new Uint8Array(buf);
          //3、返回指定位置的字符的Unicode编码
          for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        } else {
          var buf = new Array(s.length);
          for (var i = 0; i != s.length; ++i) buf[i] = s.charCodeAt(i) & 0xFF;
          return buf;
        }
      },
      saveAs(obj, fileName) {//当然可以自定义简单的下载文件实现方式
        var tmpa = document.createElement("a");
        tmpa.download = fileName ? fileName + '.xls' : new Date().getTime() + '.xls';
        tmpa.href = URL.createObjectURL(obj); //绑定a标签
        tmpa.click(); //模拟点击实现下载
        setTimeout(function () { //延时释放
          URL.revokeObjectURL(obj); //用URL.revokeObjectURL()来释放这个object URL
        }, 100);
      },
      uploadError(val){
        this.isdeling = true
        axios({
          method: 'get',
          url: '/api/euser/log/downloadAttachment',
          responseType: 'blob',
          params: {
            logId : val
          },
          headers: {
            'Content-Type': "application/json;charset=utf-8",
            'Authorization': this.getCookies('Authorization'),
            'group' :process.env.VUE_APP_GROUP
          }
        }).then((res) => {
          let data = res.data
          let fileReader = new FileReader();
          fileReader.onload = function() {
            try {
              let jsonData = JSON.parse(this.result);  // 说明是普通对象数据，后台转换失败
              if (jsonData.status == '40301') { // 接口返回的错误信息
                einvAlert.error("提示",jsonData.message) // 弹出的提示信息
              }
            } catch (err) {   // 解析成对象失败，说明是正常的文件流
              let url = window.URL.createObjectURL(new Blob([data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" }))
              let link = document.createElement('a')
              link.style.display = 'none'
              link.href = url;
              var now = window.formatDate(new Date(), 'yyyyMMddhhmmss')
              link.setAttribute('download', '发票导入失败数据' + now + '.xls')
              document.body.appendChild(link)
              link.click()
              document.body.removeChild(link)
              // err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
            }
          };
          fileReader.readAsText(data)  // 注意别落掉此代码，可以将 Blob 或者 File 对象转根据特殊的编码格式转化为内容(字符串形式)
          this.isdeling = false
        }).catch(err=>{
          this.isdeling = false
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
        //  einvAlert.error("提示",err)
        })
      },
      LoginByAccToken(){
        let  _this =this;
        this.setToken('companyId',this.$route.query.companyId)
        this.companyId = this.$route.query.companyId
        // this.setToken('phone',this.$route.query.phone)
        // console.log(this.$route.query.companyId,'12121111222');
        // console.log(this.$route.query.phone,'232323');
        this.$store.dispatch('LoginByAccToken',{
          accToken:this.accToken,
          companyId:this.companyId,
          phone:this.phone,
        }).then(res => {
          if(!res.success){
            einvAlert.error("提示",res.msg||res.message)
          }else{
            _this.isHasTask()
          }
        }).catch(err =>{
        err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
        })
      },
      LoginByEaycToken(){
        let  _this =this;
        this.setToken('companyId',this.$route.query.companyId)
        this.companyId = this.$route.query.companyId
        this.$store.dispatch('LoginByEaycToken',{
          accToken:this.accToken,
          companyId:this.companyId,
          phone:this.phone,
        }).then(res => {
          if(!res.success){
            einvAlert.error("提示",res.msg||res.message)
          }else{
            _this.isHasTask()
          }
        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')
        })
      },
      isHasTask(){
        const _this = this
        this.setToken('preUploadClientId',this.requestFrom+'_'+this.companyId+'_'+this.invoiceType+'_'+this.accUserId) //保存传参标记 clientId
        this.$store.dispatch('IsHasTask',{
          clientId:this.getToken('preUploadClientId')
        }).then(res => {
          if(res.success){
            //弹窗-判断是否继续上次操作
            if(res.data.isHasTask){
              einvAlert.confirm(function(){_this.confirmHandle(res.data.toPath,_this.invoiceType)}
                  ,"您上次的发票导入操作尚未完成，是否需要继续上次操作？"
                  ,'确认'
                  ,'取消'
                  ,function(){_this.cancelHandle()})
            }else{
              this.importVis = true;
            }
          }else {
            res ? einvAlert.error("提示",res.msg||res.message) : einvAlert.error("提示",'未知异常，稍后再试')
          }

        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')

        })
      },
      /* 确认操作，继续上次操作 */
      confirmHandle(toPath,invoiceType){
        if(toPath=="1"){//发票矫正页面
          this.setToken('uploadListShow',true)
        }else{
          this.setToken('uploadListShow',false)
        }
        setTimeout(()=>{
          this.$router.push({
            path:'/invoiceInfo',
            query:{
              /* 业务类型  1-进项，2-销项 */
              invoiceType:this.invoiceType,
              requestFrom:this.requestFrom
              // visible:true
            }
          })
        },0)
        this.$router.push({
          path:'/invoiceInfo',
          query:{
            /* 业务类型  1-进项，2-销项 */
            invoiceType:this.invoiceType,
            requestFrom:this.requestFrom
            // visible:true
          }
        })

      },
      /* 取消上次操作，清除上次数据，弹窗-上传图片 (已完成) */
      cancelHandle(){
        /* 调清除上次数据接口 */
        this.$store.dispatch('PostCancelUpload',{
          clientId:this.getToken('preUploadClientId')
        }).then(res =>{
          if(!res.success){
            einvAlert.error("提示",res.msg||res.message)
          }
        }).catch(err =>{
          err ? einvAlert.error("提示",err.msg||err.message||err) : einvAlert.error("提示",'未知异常，稍后再试')

        })
        this.importVis = true;
      },
    },
    watch:{
      message(val){ //状态提示 显隐
        if(val){
          setTimeout(()=>{
            this.message = ''
          },1500)
        }
      },
      fileList:{
        handler(){

        },
        immediate: true

      }
    },
    beforeCreate(){
      sessionStorage.clear();
      localStorage.clear();
    },
    created(){
    },
    mounted(){
      // this.LoginByAccToken()
      this.LoginByEaycToken()
    }
  }
</script>

<style lang="less" scoped>
.jumpContain {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #fff;
}
h2 {
  margin: 80px 0 33px;
  font-size: 32px;
  color: #333;
}
.f16 {
  font-size: 17px;
  line-height: 34px;
  margin: 0;

}
.f20 {
  font-size: 17px;
}
.color-3 {
  color: #333;
}
.color-blue {
  color: rgb(60, 119, 228);
}
.upLoad {
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  width: 600px;
  height: 260px;
  margin-top: 24px;
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  border-radius: 8px;

}
.el-icon-upload {
  font-size: 80px;
  color: #C0C4CC;
  margin: 54px 0 28px;
  line-height: 50px;
}

.el-upload__text{
  color: #333;
  em{
    font-style: inherit;
    cursor: pointer;
    color: #409EFF;
  }
  p{
    margin: 10px 0;
  }
}

.showList{
  margin: 6px 2px;
  padding: 0;
  max-height: 100px;
  overflow-y: scroll;
  li{
    display: flex;
    list-style: none;
    margin: 4px 0px;
    justify-content: space-between;
    span{
      cursor: pointer;
    }
    .remove{
      cursor: pointer;
      margin: 0 20px;
    }
    .remove:hover{
      color: #409EFF;
    }
  }
}
.listWrap {
  height: 100px;
}
.tipsWrap {
  height: 16px;
}
.btnWrap {
  display: flex;
  justify-content: center;
  width: 600px;

}

.tips{
  color: #FF0000;
  padding: 0;
  margin: 0;
}
.show {
  animation: hide 1s ease;
}
@keyframes hide {
  0% {opacity: 0;}
  100% {opacity: 0.9;}
}

.btn2{
  font-size: 16px;
  margin: 6px 40px;
  background: #eaf5fd;
  border: 1px solid #63a5e6;
  border-radius: 8px;
  color: #4088d0;
  height: 40px;
  width: 120px;
}
</style>
